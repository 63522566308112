import { template as template_fc7024d8d6384037837451823fef9486 } from "@ember/template-compiler";
const FKControlMenuContainer = template_fc7024d8d6384037837451823fef9486(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
